.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 300px;
  height: 400px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.inside_card{
  font-family: Impact, fantasy;
  color: #A52A2A;
  margin: 10px;
}