.footer{
    display: flex;
    flex-direction: column;
    background-color:#2B2B32;
    height:200px;
    padding-top: 30px;
    position: relative;
}

.footer_logo{
    background-image: url(../assets/logo.svg);
    height: 80px;
    width: 75px;
    background-size: cover;
    margin: auto;
}
.footer_contat{
    color:#b3b3b3;
    top:0px;
    left:10px;
    margin: auto;
}

p{
    margin: 0px;
}

h3{
    margin: 0px;
    margin-top: 10px;

}