
.header_container {
    display: flex;
    position : relative;
    height: 500px;
    background-image: url(../assets/computer-programmer-using-laptop.jpg);
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.ref{
    bottom: 10px;
    position: absolute;
}

.logo{
    margin: auto;
    width: 500px;
}
.img{
    width: 200px;
}

.titre{
    font-family: Impact, fantasy;
    font-size:40px;
    color: #A52A2A;
    margin: 0px;
}
.menu{
    display: none;
    background-color:#2B2B32;
    height: 50px;
}

@media screen and (max-width: 550px) {
    .logo{
        margin: auto;
        margin-left: 30px;
        width: 500px;
    }
}

@media screen and (min-width: 1000px){
    .center_form{
        position : absolute;
        bottom: 0px;
        right :0px;
        width    : 0;
        height   : 0;
        z-index  : 99;
        border-left   : 550px solid transparent;
        border-right  : 133px solid transparent;
        border-bottom : 200px solid #A52A2A;
    }
    
    .left_form {
        position : absolute;
        right :50px;
        bottom: 0px;
        width    : 0;
        height   : 0;
        z-index  : 100;
        border-left   : 450px solid transparent;
        border-right  : 215px solid transparent;
        border-bottom : 120px solid red;
    }
    .right_form {
        position : absolute;
        bottom: 0px;
        right: 0px;
        width    : 0;
        height   : 0;
        z-index  : 98;
        border-left   : 400px solid transparent;
        border-right  : 0px solid transparent;
        border-bottom : 450px solid  #E10B0B;
    }
}